import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('breaking_news_component', () => ({
  element: null,
  articlesWrapper: null,
  showComponent: false, 
  animatedComponents: [],
  articleTotal: 1,
  articleShownIndex: 0,
  articleLoop: null,
  init () {
    this.element = this.$el

    this.articlesWrapper = this.element.querySelectorAll("[data-id=articles]")[0]

    let allArticleElements = this.articlesWrapper.children;
    
    this.articleTotal = allArticleElements.length;

    let breakingIds = [];
    for(let i = 0; i < this.articleTotal; i++){
      breakingIds.push(allArticleElements[i].dataset.id)
    }

    const localStorageArticles = localStorage.getItem("breaking");

    let hideIfDismissed = true;

    if(localStorageArticles === null) {

      localStorage.setItem("breaking", breakingIds)
      localStorage.setItem("breaking_banner_dismissed", 0)

    } else {

      for(let i = 0; i < breakingIds.length; i++){
        
        if(localStorageArticles.indexOf(breakingIds[i]) === -1) {
          localStorage.setItem("breaking_banner_dismissed", 0)
          localStorage.setItem("breaking", breakingIds)
          break;
        }
      }
    } 
    
    this.showComponent = localStorage.getItem("breaking_banner_dismissed") != 1;
    
    if(this.articleTotal > 1) {
      this.articleLoop = setInterval(() => {this.loopArticles(this)}, 10000);
    }
  },
  loopArticles(obj) {

    let localIndex = obj.articleShownIndex;
    localIndex++;
    
    if(localIndex == obj.articleTotal) {
      obj.articleShownIndex = 0;
    } else {
      obj.articleShownIndex = localIndex;
    }

    if(this.showComponent === false) {
      clearInterval(this.articleLoop);
    }
  },
  dismiss() {
    this.showComponent = false;
    localStorage.setItem("breaking_banner_dismissed", 1);
  }
}))