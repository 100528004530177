import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("loop_embed", (invite_id, folded) => ({
  invite_id: invite_id,
  iframe: null,
  show_loop: !folded,
  init() {
    let splitInviteID = this.invite_id.split("/");

    if(splitInviteID.length === 0) {
      return;
    }

    this.invite_id = splitInviteID[splitInviteID.length - 2];

    this.iframe = this.$refs.loop_iframe;

    window.addEventListener('message', this.handleMessage.bind(this), false)
  },

  handleMessage(n) {
    n = n.data;
    
    if (n.uuid && n.uuid !== this.invite_id){
      return;
    }
    if (n.height && n.height !== this.iframe.height) {
      this.iframe.height = n.height + 'px';
    }
  },
  toggle_embed() {
    this.show_loop = !this.show_loop;
  }  
}));