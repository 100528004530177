import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('duration', (config) => ({
  duration_in_seconds: config.duration_in_seconds,
  machineDuration: false,
  pluralize(number, noun) {
    if (number == 1) return noun;
    if (noun == 'time') return 'timer';
    if (noun == 'minut') return 'minutter';
    if (noun == 'sekund') return 'sekunder';
  },
  getReadableTime(totalSeconds){
    let hours = Math.floor(totalSeconds/3600);
    let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = Math.round(totalSeconds - (hours * 3600) - (minutes * 60));

    let formattedArray = []
    let hideSeconds = false;

    this.machineDuration = hours + "h " + minutes + "m " + seconds + "s"

    if(hours) {
      formattedArray.push(`${hours} ${this.pluralize(hours, 'time')}`)
      hideSeconds = true;
    }
    if(minutes) {
      formattedArray.push(`${minutes} ${this.pluralize(minutes, 'minut')}`)
    }
    /*if(seconds && !hideSeconds) {
      formattedArray.push(`${seconds} ${this.pluralize(seconds, 'sekund')}`)
    }*/

    return formattedArray.join(" og ");
  },
  currentDuration() {
    return this.getReadableTime(this.duration_in_seconds);
  }
}));
